import { Flex, Text } from '@mantine/core';
import { CiMail } from 'react-icons/ci';
import { ImageReDirect } from '../ImageReDirect/ImageReDirect';
import { Smartberry } from '../../constant';
import style from './Footer.module.scss';
import { t } from '../../language/translations';

export const Footer = () => {
    return (
        <Flex align="center" direction="column" gap={8}>
            <Flex align="center" justify="center" h={'100%'} gap={8}>
                {Object.values(Smartberry.platforms).map(p => (
                    <ImageReDirect key={p.url} src={p.logo} url={p.url} maw={32} />
                ))}
                <Flex gap={4} align={'center'}>
                    <a href={`mailto:${Smartberry.email}`} className={style.link}>
                        <CiMail size={32} />
                    </a>
                    <a href={`mailto:${Smartberry.email}`} className={style.link}>
                        <Text fz={18}>{Smartberry.email}</Text>
                    </a>
                </Flex>
            </Flex>
            <div className={style.text}>{t('text/weDevelopAndPublish')}</div>
            {/* <div className={style.text}>{t('text/weHelpYouReleaseGame')}</div> */}
            <div className={style.text}>
                {t('text/toCooperateWriteTo')}
                {` `}
                <a href={`mailto:${Smartberry.email}`} className={style.textLink}>
                    {Smartberry.email}
                </a>
            </div>
        </Flex>
    );
};
