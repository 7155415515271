import iconVk from '../images/platform/vk-icon.png';
import iconOk from '../images/platform/ok-icon.png';

export const Smartberry = {
    email: 'smartberry@mail.ru',
    platforms: {
        Vk: {
            logo: iconVk,
            url: 'https://vk.com/smartberry_games',
        },
        Ok: {
            logo: iconOk,
            url: 'https://ok.ru/group/70000005108745',
        },
    },
};
