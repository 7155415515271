import iconVk from '../images/platform/vk-icon.png';
import iconOk from '../images/platform/ok-icon.png';
import iconYg from '../images/platform/yandex-games-icon.jpeg';
import iconCrazyGames from '../images/platform/crazy-games-purple-icon.png';
import iconCrazyGamesLargePurple from '../images/platform/crazy-games-large-purple-icon.svg';
import iconGooglePlay from '../images/platform/google-play-icon.png';
import iconGM from '../images/platform/gamemonetize-icon.png';
import iconGD from '../images/platform/gd.png';
import iconGamePix from '../images/platform/game-pix-icon.png';
import iconPoki from '../images/platform/poki-icon.webp';
import iconAppStore from '../images/platform/apple-app-store-icon.png';
import iconAppGallery from '../images/platform/huawei-app-gallery-icon.png';
import iconRuStore from '../images/platform/ru-store-icon.png';
import iconNashStore from '../images/platform/nash-store-icon.png';
import iconTelegram from '../images/platform/telegram.png';

export const gamesCommon = {
    chess: {
        title: 'title/chess',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                icon: iconYg,
                url: 'https://yandex.ru/games/app/228794?utm_source=refferal_program&utm_medium=delank-myhd&clid=2968864',
            },
            crazyGames: {
                icon: iconCrazyGames,
                url: 'https://www.crazygames.com/game/chess-free',
            },
            ok: {
                icon: iconOk,
                url: 'https://ok.ru/game/chess-free',
            },
            vk: {
                icon: iconVk,
                url: 'https://vk.com/app51798267',
            },
            rustore: {
                icon: iconRuStore,
                url: 'https://www.rustore.ru/catalog/app/com.smartberry.chess',
            },
            appGallery: {
                icon: iconAppGallery,
                url: 'https://appgallery.huawei.com/app/C111554883',
            },
            googlePlay: {
                icon: iconGooglePlay,
                url: 'https://play.google.com/store/apps/details?id=com.smartberry.chess',
            },
            gameMonetize: {
                icon: iconGM,
                url: 'https://gamemonetize.com/chess-free-game',
            },
            gamePix: {
                icon: iconGamePix,
                url: 'https://www.gamepix.com/play/chess-for-free',
            },
            poki: {
                icon: iconPoki,
                url: 'https://poki.com/ru/g/chess-free',
            },
            telegram: {
                icon: iconTelegram,
                url: 'https://t.me/playdeckbot/game?startapp=eyJnYW1lIjp7ImtleSI6InhFWENHY0RsVGkifX0=',
            },
        },
    },
    checkersRu: {
        title: 'title/checkersRu',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                icon: iconYg,
                url: 'https://yandex.ru/games/app/364458?utm_source=refferal_program&utm_medium=delank-myhd&clid=10371474',
            },
            crazyGames: {
                icon: iconCrazyGames,
                url: 'https://www.crazygames.com/game/russian-checkers-free',
            },
            ok: {
                icon: iconOk,
                url: 'https://ok.ru/game/512002818335',
            },
            vk: {
                icon: iconVk,
                url: 'https://vk.com/games/app52172230',
            },
            rustore: {
                icon: iconRuStore,
                url: 'https://www.rustore.ru/catalog/app/com.smartberry.checkers_ru',
            },
            appGallery: {
                icon: iconAppGallery,
                url: 'https://appgallery.huawei.com/app/C112205949',
            },
            googlePlay: {
                icon: iconGooglePlay,
                url: 'https://play.google.com/store/apps/details?id=com.smartberry.checkers_ru',
            },
            gameMonetize: {
                icon: iconGM,
                url: 'https://gamemonetize.com/russian-checkers-game',
            },
            telegram: {
                icon: iconTelegram,
                url: 'https://t.me/playdeckbot/game?startapp=eyJnYW1lIjp7ImtleSI6ImpiR3NEOVZwZXoifX0=',
            },
        },
    },
    checkersPoddavki: {
        title: 'title/poddavki',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                icon: iconYg,
                url: 'https://yandex.ru/games/app/368846?utm_source=refferal_program&utm_medium=delank-myhd&clid=10788576',
            },
            ok: {
                icon: iconOk,
                url: 'https://ok.ru/game/poddavkiru',
            },
            vk: {
                icon: iconVk,
                url: 'https://vk.com/app52339827',
            },
            rustore: {
                icon: iconRuStore,
                url: 'https://www.rustore.ru/catalog/app/com.smartberry.poddavki',
            },
            appGallery: {
                icon: iconAppGallery,
                url: 'https://appgallery.huawei.com/app/C112207905',
            },
            googlePlay: {
                icon: iconGooglePlay,
                url: 'https://play.google.com/store/apps/details?id=com.smartberry.poddavki&hl=en',
            },
            gameMonetize: {
                icon: iconGM,
                url: 'https://gamemonetize.com/giveaway-checkers-game',
            },
            telegram: {
                icon: iconTelegram,
                url: 'https://t.me/playdeckbot/game?startapp=eyJnYW1lIjp7ImtleSI6IkxoU1pXVllCc24ifX0=',
            },
        },
    },
    checkersEn: {
        title: 'title/checkersEn',
        mainPlatformCode: 'crazyGames',
        platforms: {
            crazyGames: {
                icon: iconCrazyGamesLargePurple,
                url: 'https://www.crazygames.com/game/checkers-free',
            },
        },
    },
    petsAndPaints: {
        title: 'title/petsAndPaints',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                icon: iconYg,
                url: 'https://yandex.ru/games/app/225080?utm_source=refferal_program&utm_medium=delank-myhd&clid=9491731',
            },
            crazyGames: {
                icon: iconCrazyGames,
                url: 'https://www.crazygames.com/game/timberland-arrange-puzzle-game',
            },
            vk: {
                icon: iconVk,
                url: 'https://vk.com/games/app52094267',
            },
            ok: {
                icon: iconOk,
                url: 'https://ok.ru/game/512001416278',
            },
            gameMonetize: {
                icon: iconGM,
                url: 'https://gamemonetize.com/timberland-arrange-puzzle-game-game',
            },
            gameDistribution: {
                icon: iconGD,
                url: 'https://html5.gamedistribution.com/89b674adbc344c52b3e92fa0e435aacc/',
            },
            rustore: {
                icon: iconRuStore,
                url: 'https://apps.rustore.ru/app/com.delank.timberland',
            },
            googlePlay: {
                icon: iconGooglePlay,
                url: 'https://play.google.com/store/apps/details?id=com.delank.timberland',
            },
            appStore: {
                icon: iconAppStore,
                url: 'https://apps.apple.com/by/app/timberland-puzzle-game/id1563556047',
            },
            appGallery: {
                icon: iconAppGallery,
                url: 'https://appgallery.cloud.huawei.com/ag/n/app/C107157987',
            },
            nashStore: {
                icon: iconNashStore,
                url: 'https://store.nashstore.ru/store/62864512fb3ed3501d52cfc0',
            },
        },
    },
    marketplace: {
        title: 'title/marketplace',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                icon: iconYg,
                url: 'https://yandex.ru/games/app/337436?utm_source=refferal_program&utm_medium=delank-myhd&clid=9491668',
            },
            vk: {
                icon: iconVk,
                url: 'https://vk.com/games/app52018804',
            },
        },
    },
    retroRacerTurbo: {
        title: 'title/retroRacerTurbo',
        mainPlatformCode: 'yandexGames',
        platforms: {
            yandexGames: {
                icon: iconYg,
                url: 'https://yandex.ru/games/app/264148?utm_source=refferal_program&utm_medium=delank-myhd',
            },
        },
    },
}