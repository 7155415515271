import React, { useEffect, useState } from 'react';

import { Container, Flex } from '@mantine/core';

import style from './Cards.module.scss';
import Card from '../Card/Card';
import { selectedAppLocale } from '../../language/translations';
import { AppLocale } from '../../language/appLocale';

const getGames = {
    [AppLocale.EN]: () => require('../../constant/games.en'),
    [AppLocale.RU]: () => require('../../constant/games.ru'),
}[selectedAppLocale] ?? (() => {});

export const Cards = () => {
    const [games, setGames] = useState({});

    useEffect(() => {
        setGames(getGames()?.games ?? {});
    }, []);

    return (
        <Container fluid pt={16} pb={32}>
            <Flex justify="center" wrap="wrap" gap={32} className={style.cards}>
                {Object.entries(games).map(([gameCode, game]) => (
                    <Card key={gameCode} game={game} gameCode={gameCode} />
                ))}
            </Flex>
        </Container>
    );
};
